import { subDays, isSameDay } from 'date-fns'
import { DateRange } from 'react-day-picker'
import classNames from 'classnames'
import { ReactNode } from 'react'

const PresetButton = ({
  selected,
  date,
  onClick,
  children,
}: {
  selected: DateRange | undefined
  date: DateRange
  onClick: (date: DateRange) => void
  children: ReactNode
}) => {
  const isActive =
    selected &&
    selected.from &&
    selected.to &&
    isSameDay(selected.from, date.from!) &&
    isSameDay(selected.to, date.to!)
  return (
    <button
      className={classNames('text-left text-gray-400', {
        'text-teal-800': isActive,
      })}
      onClick={() => {
        onClick(date)
      }}
    >
      {children}
    </button>
  )
}

const PresetButtons = ({
  selected,
  onClick,
}: {
  selected: DateRange | undefined
  onClick: (date: DateRange) => void
}) => {
  return (
    <div className="flex flex-col justify-start gap-3">
      <PresetButton
        selected={selected}
        date={{ from: new Date(), to: new Date() }}
        onClick={onClick}
      >
        Today
      </PresetButton>
      <PresetButton
        selected={selected}
        date={{ from: subDays(new Date(), 7), to: new Date() }}
        onClick={onClick}
      >
        Past 7 days
      </PresetButton>
      <PresetButton
        selected={selected}
        date={{ from: subDays(new Date(), 30), to: new Date() }}
        onClick={onClick}
      >
        Past 30 days
      </PresetButton>
      <PresetButton
        selected={selected}
        date={{ from: subDays(new Date(), 90), to: new Date() }}
        onClick={onClick}
      >
        Past 90 days
      </PresetButton>
      <PresetButton
        selected={selected}
        date={{ from: subDays(new Date(), 365), to: new Date() }}
        onClick={onClick}
      >
        Past 12 months
      </PresetButton>
    </div>
  )
}

export default PresetButtons
