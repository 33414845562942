import {
  Badge,
  Skeleton,
  formatCurrency,
  LocationFunnelState,
} from '@changex/design-system'
import { TApplication, TFund } from 'features/applications/types'
import { orderBy } from 'lodash'
import { useQuery as useJsonApiQuery } from 'jsonapi-react'
import dayjs from 'dayjs'

const fundUrl = (slug: string) => {
  return `${process.env.REACT_APP_CHANGEX_ADDRESS}/funds/${slug}`
}

const STATUSES_COUNTED_TOWARDS_LIMIT = [
  LocationFunnelState.PreAllocated,
  LocationFunnelState.Allocated,
  LocationFunnelState.Approved,
  LocationFunnelState.Succeeded,
  LocationFunnelState.PaidSeed,
  LocationFunnelState.Impact,
  LocationFunnelState.PaidImpact,
  LocationFunnelState.FailedImpact,
]

enum TFundStatus {
  live = 'live',
  upcoming = 'upcoming',
  closed = 'closed',
}

const FundStatus = ({ status }) => {
  if (status === TFundStatus.live) {
    return (
      <Badge variant="success" className="text-sm 2xl:text-base">
        Live
      </Badge>
    )
  }
  if (status === TFundStatus.upcoming) {
    return (
      <Badge variant="open_grant" className="text-sm 2xl:text-base">
        Upcoming
      </Badge>
    )
  }
  return <span className="text-gray-600">Closed</span>
}

const Fund = ({
  fund,
  solutionSlug,
  solutionId,
  isLoadingBalance,
}: {
  fund: TFund
  solutionSlug: string
  solutionId: string
  isLoadingBalance: boolean
}) => {
  const { meta, isLoading: loadingApplications = true } = useJsonApiQuery<
    TApplication[]
  >([
    'applications',
    {
      filter: {
        fund_id: fund.id,
        solution_id: solutionId,
        status: STATUSES_COUNTED_TOWARDS_LIMIT.join(','),
      },
      page: { size: 0 }, // get stats only
      stats: { total: 'count' },
    },
  ])
  const currentApplicationCount = meta?.stats.total.count || 0
  const portfolioOptions = fund.options.portfolio?.find(
    (item) => item.solutionId === `${solutionId!.toString()}-${solutionSlug}`
  )
  const isUpcoming =
    !fund.isLive && dayjs(fund.options.startDate).isAfter(dayjs())
  const fundStatus = fund.isLive
    ? TFundStatus.live
    : isUpcoming
    ? TFundStatus.upcoming
    : TFundStatus.closed
  return (
    <tr data-testid="fund">
      <td className="w-28 px-2 py-5 pl-4 text-sm 2xl:text-base">
        <FundStatus status={fundStatus} />
      </td>
      <td className="px-2 py-5 text-left text-sm 2xl:text-base">
        {dayjs(fund.options.startDate).format('D MMM, YYYY')}
      </td>
      <td className="flex flex-col gap-0.5 px-2 py-5 text-sm 2xl:text-base">
        <a href={fundUrl(fund.slug)}>{fund.name}</a>
        <div className="text-xs text-gray-500">{fund.internalIdentifier}</div>
      </td>
      <td className="px-2 py-5 text-sm text-gray-600 2xl:text-base">
        {fund.options.currency}
      </td>
      <td className="px-2 py-5 text-right text-sm 2xl:text-base">
        {formatCurrency(fund.options.amount!, fund.options.currency!)}
      </td>
      <td className="px-2 py-5 text-right text-sm 2xl:text-base">
        {!isLoadingBalance ? (
          formatCurrency(fund.balance!, fund.options.currency!)
        ) : (
          <Skeleton className="h-6" />
        )}
      </td>
      <td className="px-2 py-5 text-right text-sm 2xl:text-base">
        {formatCurrency(
          portfolioOptions?.solutionAllocations.seed || 0,
          fund.options.currency!
        )}
      </td>
      <td className="px-2 py-5 text-right text-sm 2xl:text-base">
        {loadingApplications ? (
          <Skeleton className="h-6" />
        ) : (
          currentApplicationCount
        )}
      </td>
      <td className="px-2 py-5 text-right text-sm 2xl:text-base">
        {portfolioOptions?.maxReplications}
      </td>
    </tr>
  )
}

const Funds = ({
  funds,
  solutionSlug,
  solutionId,
}: {
  funds: TFund[]
  solutionSlug: string
  solutionId: string
}) => {
  // The app-level solutions query includes funds, but we need to request a slow extra_field "balance" for this page.
  // In order to make the experience feel faster for users, we display the already-loaded funds first, and get the
  // balance in the meantime. We could just fetch only the `balance` field and merge it in to the pre-fetched data,
  // but to avoid weird issues with shallow object merging, we're just re-fetching the whole fund with the extra data.
  const { data: fundsWithBalanceData, isLoading: isLoadingBalance = true } =
    useJsonApiQuery<TFund[]>([
      'funds',
      {
        filter: { id: funds.map((fund) => fund.id).join(',') },
        extra_fields: { funds: 'balance' },
        page: {
          size: 999, // Basically removing pagination. We've backgrounded this request so it's okay if it takes a while
        },
      },
    ])
  const fundsToDisplay = orderBy(
    fundsWithBalanceData || funds,
    ['options.startDate'],
    ['desc']
  )

  return (
    <>
      {fundsToDisplay.map((fund) => (
        <Fund
          key={fund.id}
          fund={fund}
          solutionSlug={solutionSlug}
          solutionId={solutionId}
          isLoadingBalance={isLoadingBalance}
        />
      ))}
    </>
  )
}

export default Funds
