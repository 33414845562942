import { Stat, StatGroup, formatNumber } from '@changex/design-system'

const SimpleStats = ({
  completedSurveys,
  beneficiaries,
  childrenBeneficiaries,
  childrenParticipants,
  participants,
  totalApplications,
  fundedTeams,
  className,
}: {
  completedSurveys: number
  beneficiaries: number
  childrenBeneficiaries: number
  childrenParticipants: number
  participants: number
  totalApplications: number
  fundedTeams: number
  className?: string
}) => {
  return (
    <StatGroup className={className}>
      <Stat
        title="People impacted"
        tooltipContent={
          <p>
            The number of people impacted by the project, as reported in the
            impact survey.
            <br />
            This includes participants and the community members who indirectly
            benefited.
          </p>
        }
        value={beneficiaries}
        secondaryText={
          childrenBeneficiaries === 1
            ? '1 child'
            : `${formatNumber(childrenBeneficiaries)} children`
        }
      />
      <Stat
        title="Participants"
        tooltipContent={
          <p>
            The number of people who have actively taken part in the project
            activities, as reported in the impact survey.
            <br />
            This includes project team members and participants.
          </p>
        }
        value={participants}
        secondaryText={
          childrenParticipants === 1
            ? '1 child'
            : `${formatNumber(childrenParticipants)} children`
        }
      />
      <Stat
        title="Funded teams"
        tooltipContent={
          <p>
            Applicants which have received funding.
            <br />
            Included states: paid seed, impact, paid impact, failed impact.
          </p>
        }
        value={fundedTeams}
        secondaryText={
          completedSurveys === 1
            ? '1 completed impact survey'
            : `${formatNumber(completedSurveys)} completed impact surveys`
        }
      />
      <Stat
        title="Total applications"
        tooltipContent={
          <p>
            All applications for this idea.
            <br />
            Included states: waitlist, replication inbox, allocated, succeeded,
            approved, paid seed, impact, paid impact, failed, failed impact,
            rejected, refunded, unfunded
          </p>
        }
        value={totalApplications}
      />
    </StatGroup>
  )
}

export default SimpleStats
