import { Input, Checkbox, Selector, Text, Button } from '@changex/design-system'
import { useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'
import { TFilter } from '../../types'
import { constants } from '../../constants'
import { XIcon } from '@heroicons/react/solid'
import { useSearchParams } from 'react-router-dom'

const isFilterApplied = (filtersObj) => {
  const newObj = { ...filtersObj }
  delete newObj.sort
  delete newObj.page
  return Object.keys(newObj).length > 0
}

type TOption = {
  id: string
  label: string
}

type TProps = {
  onFiltering: (filters: TFilter) => void
  funds: Partial<TOption>[]
}

const StyledSelector = styled(Selector)`
  div {
    margin-top: 0;
  }

  ul {
    margin-top: 0.5rem;
  }
`

const ApplicationsFilters: React.FC<TProps> = ({ onFiltering }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersObj = Object.fromEntries(searchParams)
  const [nameEmailFilter, setNameEmailFilter] = useState<string>(
    filtersObj.q ?? ''
  )
  const [sortBy, setSortBy] = useState<string>(
    filtersObj.sort ?? constants.sorts[0].id
  )
  const [status, setStatus] = useState<string>(
    filtersObj.status ?? constants.status[0].id
  )
  const [inChallenge, setInChallenge] = useState<boolean>(
    !!filtersObj.in_challenge ?? false
  )

  const resetState = () => {
    onFiltering({})
    setSearchParams({})

    setNameEmailFilter('')
    setStatus(constants.status[0].id)
    setInChallenge(false)
  }

  useDebounce(
    () => {
      onFiltering({
        q: nameEmailFilter,
        status,
        sort: sortBy,
        in_challenge: inChallenge,
      })
    },
    500,
    [nameEmailFilter, inChallenge, sortBy, status]
  )

  const handleNameEmailFilter = (event) => {
    setNameEmailFilter(event.target.value)
  }

  return (
    <div className="mt-9 mb-4 flex items-center gap-x-4">
      <div>
        <Input
          ariaLabel="Filter by name, email, organisation..."
          className="w-96"
          onChange={handleNameEmailFilter}
          placeholder="Filter by name, email, organisation..."
          type="text"
          value={nameEmailFilter}
        />
      </div>
      <div className="flex items-center">
        <StyledSelector
          onChange={(option) => setStatus(option)}
          options={constants.status}
          value={filtersObj.status ?? status}
        />
      </div>
      {/* <div className="flex items-center">
        <StyledSelector
          onChange={(option) => setStep(option)}
          options={constants.steps}
          value={step}
        />
      </div>
      <div className="flex items-center">
        <StyledSelector
          onChange={(option) => setFund(option)}
          options={[...constants.funds, ...funds]}
          value={fund}
          filterOptions={{
            enabled: true,
            fluid: {
              width: true,
            },
            placeholder: 'Filter funds by name',
          }}
        />
      </div> */}
      <div className="flex items-center">
        <Text className="z-10 mr-1 whitespace-nowrap pl-4" variant="light">
          Sort by
        </Text>
        <StyledSelector
          onChange={(option) => setSortBy(option)}
          options={constants.sorts}
          value={filtersObj.sort ?? sortBy}
        />
      </div>
      {/* <div className="ml-2">
        <Checkbox
          label="Funded"
          checked={!!isFunded}
          onChange={() => setIsFunded((oldState) => !oldState)}
          testId="checkbox-funded"
        />
      </div> */}
      <div className="ml-2 hidden">
        <Checkbox
          label="In challenge"
          checked={!!filtersObj.in_challenge}
          onChange={() => setInChallenge((oldState) => !oldState)}
          testId="checkbox-in-challenge"
        />
      </div>
      <div>
        {isFilterApplied(filtersObj) ? (
          <Button
            weight="tertiary"
            className="border-0 text-red-600 shadow-none"
            onClick={resetState}
          >
            <XIcon className="h-5 w-5 text-red-600" />
            Clear filters
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default ApplicationsFilters
