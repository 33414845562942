import {
  TSolutionSurveyResponse,
  TSolutionSurveyResponseFieldOrder,
} from 'shared/api/survey-responses'
import { ReactNode, useMemo } from 'react'
import { FormattedFieldValue } from '@changex/design-system'

const TableCell = ({ children }: { children?: ReactNode }) => {
  return (
    <td className="border border-gray-100 px-4 pt-2 text-start align-top font-medium">
      {children}
    </td>
  )
}

const TableHead = ({ children }: { children?: ReactNode }) => {
  return (
    <th className="sticky top-0 h-8 whitespace-nowrap border border-gray-100 bg-white px-4 text-start text-sm font-medium text-gray-500">
      {children}
    </th>
  )
}

type Props = {
  data: TSolutionSurveyResponse[]
  fieldOrder: TSolutionSurveyResponseFieldOrder
  onRowClick: (id: string) => void
}

export const SurveyResponses = ({ data, fieldOrder, onRowClick }: Props) => {
  const dataMap = useMemo(() => {
    return data.map((row) => {
      return {
        ...row,
        surveyData: row.surveyData.reduce((acc, curr) => {
          acc[curr.id] = curr.value
          return acc
        }, {}),
      }
    })
  }, [data])

  return (
    <table className="relative border-separate border-spacing-0">
      <thead>
        <tr>
          <TableHead>
            <span>Submitted at</span>
          </TableHead>
          <TableHead>
            <span>Project</span>
          </TableHead>
          {fieldOrder.map((field) => {
            return field.id ? (
              <TableHead key={field.id}>
                <span>{field.name}</span>
              </TableHead>
            ) : null
          })}
        </tr>
      </thead>
      <tbody>
        {dataMap.map((row) => (
          <tr
            key={row.id}
            className="cursor-pointer hover:bg-gray-50"
            onClick={() => onRowClick(row.id)}
          >
            <TableCell>
              <FormattedFieldValue
                type="date"
                value={row.submittedAt}
                textSize="sm"
              />
            </TableCell>
            <TableCell>
              <a
                href={row.locationUrl}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-sm"
              >
                {row.name}
              </a>
            </TableCell>
            {fieldOrder.map((field) =>
              row.surveyData[field.id] ? (
                <TableCell key={`${row.id}-${field.id}`}>
                  <FormattedFieldValue
                    type={field.type}
                    value={row.surveyData[field.id]}
                    textSize="sm"
                  />
                </TableCell>
              ) : (
                <TableCell key={`${row.id}-${field.id}`} />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
