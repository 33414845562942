import { AppLayout } from 'shared/layouts'
import { Toaster } from 'react-hot-toast'
import { Heading, LoadingSpinner, Skeleton } from '@changex/design-system'
import { useParams } from 'react-router-dom'
import { useFetcher } from 'shared/api/fetcher'
import { useSolutionById } from 'features/applications/hooks'
import {
  TFilterAction,
  TSolutionSurveyResponse,
} from 'shared/api/survey-responses'
import { SurveyResponses } from './SurveyResponses'
import { useReducer } from 'react'
import { TRegion, regionNameForSlug } from 'shared/api/regions'
import { constants } from 'features/applications/constants'
import { TFilters } from 'shared/api/survey-responses'
import SurveyResponseFilters from './SurveyResponseFilters'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

function filtersReducer(filters: TFilters, action: TFilterAction) {
  switch (action.type) {
    case 'reset':
      return {}
    case 'set':
      return {
        ...filters,
        [action.name]: action.value,
      }
  }
}

const filtersObjectToQueryString = (filters: TFilters) => {
  const result: Record<string, string> = {}
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      result[key] = value
    }
  })
  return result
}

const Page = () => {
  const [filters, dispatch] = useReducer(filtersReducer, {})
  const { solutionId } = useParams()
  const navigate = useNavigate()

  const {
    data: surveyResponses,
    isLoading: isLoadingSurveyResponses,
    meta: fieldData,
  } = useFetcher<TSolutionSurveyResponse[]>(
    '/analytics/solutions/survey_responses',
    {
      sort: '-submitted_at',
      'filter[solution_id]': solutionId!,
      ...filtersObjectToQueryString(filters),
    }
  )
  const { data: regions, isLoading: isLoadingRegions } = useFetcher<TRegion[]>(
    `/regions`,
    {
      'filter[solution_id]': solutionId!,
    }
  )
  const { data: solution, isLoading: isLoadingSolution } = useSolutionById(
    solutionId!
  )

  const isLoading =
    isLoadingSolution || isLoadingRegions || isLoadingSurveyResponses
  const isLoadingInitial = isLoading && !surveyResponses
  const isUpdating = isLoading && isLoadingSurveyResponses

  const regionOptions = constants.regions.concat(
    regions?.map((region) => ({
      id: region.id,
      label: regionNameForSlug(region.slug) || region.slug,
    })) || []
  )

  const fundOptions = constants.funds.concat(
    (solution &&
      solution.funds?.map((fund) => ({
        id: fund.id,
        label: fund.name,
      }))) ||
      []
  )

  return (
    <AppLayout>
      <main className="overflow-auto">
        <div className="sticky left-0 z-10">
          <div className="flex flex-col border-b border-gray-100 px-4 pt-8 pb-4">
            <Heading>Survey responses</Heading>
            {surveyResponses && solution && regions && (
              <SurveyResponseFilters
                regionOptions={regionOptions}
                fundOptions={fundOptions}
                filters={filters}
                dispatch={dispatch}
              />
            )}
          </div>
        </div>
        {isLoadingInitial ? (
          <Skeleton className="h-[680px]" />
        ) : (
          <div
            className={classNames(
              'max-h-[calc(100vh-13.5rem)] max-w-[calc(100vw-18rem)]',
              {
                'opacity-50': isUpdating,
              }
            )}
          >
            {isUpdating && (
              <div className="absolute left-1/2 top-1/2 z-10 text-black">
                <LoadingSpinner />
              </div>
            )}
            {surveyResponses && surveyResponses.length > 0 ? (
              <SurveyResponses
                data={surveyResponses!}
                fieldOrder={fieldData!.fieldOrder}
                onRowClick={navigate}
              />
            ) : (
              <div className="flex h-96 items-center justify-center">
                <Heading>No survey responses found</Heading>
              </div>
            )}
          </div>
        )}
      </main>
      <Toaster />
    </AppLayout>
  )
}

export default Page
