import {
  EMAIL_FIELD_ID,
  TSolutionSurveyResponse,
  TSolutionSurveyResponseFieldOrder,
} from 'shared/api/survey-responses'
import { FormattedFieldValue } from '@changex/design-system'
import classNames from 'classnames'

const Question = ({ title }: { title: string }) => {
  return (
    <div className="w-full max-w-[432px] font-medium text-gray-900">
      {title}
    </div>
  )
}

const Row = ({ topBorder = false, children }) => {
  return (
    <div
      className={classNames('flex flex-row border-b border-gray-100 py-6', {
        'border-t': topBorder,
      })}
    >
      {children}
    </div>
  )
}

type Props = {
  data: TSolutionSurveyResponse
  fieldOrder: TSolutionSurveyResponseFieldOrder
}

export const SurveyResponse = ({ data, fieldOrder }: Props) => {
  const fieldMetaMap = fieldOrder.reduce((acc, curr) => {
    acc[curr.id] = { name: curr.name, type: curr.type }
    return acc
  }, {})

  const email =
    (data.surveyData.find((field) => field.id === EMAIL_FIELD_ID)
      ?.value as string) || ''

  return (
    <>
      <h2 className="mb-8 pl-4 text-3xl font-bold text-gray-900">{email}</h2>
      <div className="pl-8">
        <Row topBorder={true}>
          <Question title="Submitted at" />
          <FormattedFieldValue type="date" value={data.submittedAt} />
        </Row>
        <Row>
          <Question title="Project" />
          <a href={data.locationUrl} target="_blank" rel="noreferrer">
            {data.name}
          </a>
        </Row>
        {data.surveyData.map((field) => {
          if (field.id === EMAIL_FIELD_ID) return null
          return (
            <Row key={field.id}>
              <Question title={fieldMetaMap[field.id].name} />
              <FormattedFieldValue
                type={fieldMetaMap[field.id].type}
                value={field.value}
              />
            </Row>
          )
        })}
      </div>
    </>
  )
}
