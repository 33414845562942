import { Icon, Selector } from '@changex/design-system'
import { Dispatch } from 'react'
import { TFilterAction, TFilters } from 'shared/api/survey-responses'

type SelectorOption = {
  id: string
  label: string
}

type Props = {
  regionOptions: SelectorOption[]
  fundOptions: SelectorOption[]
  filters: TFilters
  dispatch: Dispatch<TFilterAction>
}

const MIN_OPTIONS_TO_DISPLAY = 2

const SurveyResponseFilters = ({
  regionOptions,
  fundOptions,
  filters,
  dispatch,
}: Props) => {
  if (
    regionOptions.length <= MIN_OPTIONS_TO_DISPLAY &&
    fundOptions.length <= MIN_OPTIONS_TO_DISPLAY
  )
    return null

  return (
    <div className="mt-8 flex gap-4">
      {regionOptions.length > MIN_OPTIONS_TO_DISPLAY && (
        <Selector
          options={regionOptions}
          onChange={(regionId) =>
            dispatch({
              type: 'set',
              name: 'filter[region_id]',
              value: regionId,
            })
          }
          fluid={{ height: false, width: true }}
          value={filters['filter[region_id]'] || ''}
        />
      )}
      {fundOptions.length > MIN_OPTIONS_TO_DISPLAY && (
        <Selector
          options={fundOptions}
          onChange={(fundId) =>
            dispatch({
              type: 'set',
              name: 'filter[fund_id]',
              value: fundId,
            })
          }
          fluid={{ height: false, width: true }}
          value={filters['filter[fund_id]'] || ''}
          filterOptions={{
            enabled: true,
            placeholder: 'Filter funds by name',
          }}
        />
      )}
      {Object.values(filters).filter((value) => value).length > 0 && (
        <button
          className="flex items-center text-sm font-medium text-red-600"
          onClick={() => {
            dispatch({ type: 'reset' })
          }}
        >
          <Icon icon="XIcon" size="xs" />
          Clear filters
        </button>
      )}
    </div>
  )
}

export default SurveyResponseFilters
