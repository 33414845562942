import { BarChartTable } from '@changex/design-system'
import { TSolutionApplicationsOverTimeItem } from 'shared/api/analytics'
import classNames from 'classnames'
import { TFund } from '@features/applications/types'
import { ComponentProps } from 'react'
import { LocationFunnelStateCamelCase } from '@changex/design-system'

type TProps = {
  funds: TFund[]
  data: TSolutionApplicationsOverTimeItem[]
  className?: string
}

const groupDataByFundId = (
  funds: TFund[],
  data: TSolutionApplicationsOverTimeItem[]
) => {
  return data.reduce((acc, item) => {
    if (item.fundIds.length === 0) {
      return acc
    }
    for (const fundId of item.fundIds) {
      const fund = funds.find((fund) => fund.id === fundId?.toString())
      if (!acc[fundId]) {
        acc[fundId] = {
          key: fundId,
          label: (
            <div className="flex flex-col gap-0.5">
              <div>{fund?.name || `Fund ${fundId}`}</div>
              <div className="text-xs text-gray-500">
                {fund?.internalIdentifier}
              </div>
            </div>
          ),
          total: 0,
          stats: {
            waitlist: 0,
            inProgress: 0,
            funded: 0,
            unsuccessful: 0,
          },
        }
      }
      acc[fundId].total++
      switch (item.state) {
        case 'waiting':
          acc[fundId].stats.waitlist++
          break
        case LocationFunnelStateCamelCase.PreAllocated:
        case LocationFunnelStateCamelCase.Allocated:
        case LocationFunnelStateCamelCase.Approved:
        case LocationFunnelStateCamelCase.Succeeded:
          acc[fundId].stats.inProgress++
          break
        case LocationFunnelStateCamelCase.PaidSeed:
        case LocationFunnelStateCamelCase.Impact:
        case LocationFunnelStateCamelCase.PaidImpact:
          acc[fundId].stats.funded++
          break
        case LocationFunnelStateCamelCase.Rejected:
        case LocationFunnelStateCamelCase.Failed:
        case LocationFunnelStateCamelCase.Refunded:
        case LocationFunnelStateCamelCase.FailedImpact:
          acc[fundId].stats.unsuccessful++
          break
        default:
          throw new Error(`Unknown state: ${item.state}`)
      }
    }
    return acc
  }, {} as Record<number, ComponentProps<typeof BarChartTable>['rows'][0]>)
}

const ApplicationsPerFund: React.FC<TProps> = ({ funds, data, className }) => {
  const groupedData = groupDataByFundId(funds, data)
  const sortedFunds = funds.sort(
    (a, b) =>
      new Date(b.options.startDate!).getTime() -
      new Date(a.options.startDate!).getTime()
  )
  return (
    <div
      className={classNames('flex flex-col rounded bg-white p-4', className)}
    >
      <BarChartTable
        title="Applications per fund"
        primaryColumnLabel="Fund"
        legend={[
          {
            label: 'Waitlist',
            color: 'bg-gray-200',
            key: 'waitlist',
            tooltip:
              'Applications submitted after the fund has been fully allocated or all the particular idea slots have been filled.',
          },
          {
            label: 'In progress',
            color: 'bg-green-500',
            key: 'inProgress',
            tooltip:
              'Applications accepted into the fund but which have not yet received funding. Included states: replication inbox, allocated, approved, succeeded.',
          },
          {
            label: 'Funded',
            color: 'bg-teal-800',
            key: 'funded',
            tooltip:
              'Applications which have received funding. Included states: paid seed, impact, paid impact.',
          },
          {
            label: 'Unsuccessful',
            color: 'bg-teal-400/40',
            key: 'unsuccessful',
            tooltip: 'Applications which have failed or been rejected',
          },
        ]}
        rows={sortedFunds
          .map((fund) => groupedData[fund.id])
          .filter((value) => !!value)}
      />
    </div>
  )
}

export default ApplicationsPerFund
