import Calendar from './Calendar'
import PresetButtons from './date-range-picker/PresetButtons'
import { Button, Input } from '@changex/design-system'
import { DateRange, PropsRange } from 'react-day-picker'
import { useState } from 'react'
import { format, isValid, parse } from 'date-fns'
import 'react-day-picker/style.css'

// TODO: localize format
const DATE_INPUT_FORMAT = 'dd/MM/yyyy'

const DateInput = ({ id, label, value, onChange }) => {
  return (
    <div className="flex flex-col gap-1">
      <label htmlFor={id} className="text-sm font-medium text-gray-700">
        {label}
      </label>
      <Input
        id={id}
        type="text"
        placeholder={DATE_INPUT_FORMAT.toUpperCase()}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

function DateRangePicker({
  initialValue,
  onApply,
}: {
  initialValue: PropsRange['selected']
  onApply: (selected: DateRange | undefined) => void
}) {
  const [selected, setSelected] = useState(initialValue)
  const [month, setMonth] = useState(selected?.from || new Date())
  const [inputValues, setInputValues] = useState({
    from: selected?.from ? format(selected.from, DATE_INPUT_FORMAT) : '',
    to: selected?.to ? format(selected.to, DATE_INPUT_FORMAT) : '',
  })

  const handleDayPickerSelect = (date: DateRange | undefined) => {
    if (!date) {
      setInputValues({ from: '', to: '' })
      setSelected(undefined)
    } else {
      setSelected(date)
      setInputValues({
        from: date?.from
          ? format(date.from, DATE_INPUT_FORMAT)
          : inputValues.from,
        to: date?.to ? format(date.to, DATE_INPUT_FORMAT) : inputValues.to,
      })
    }
  }

  const handlePresetSelect = (date: DateRange) => {
    handleDayPickerSelect(date)
    setMonth(date.from!)
  }

  return (
    <div className="flex w-fit flex-col gap-5">
      <div className="flex gap-5">
        <PresetButtons selected={selected} onClick={handlePresetSelect} />
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-5">
            <DateInput
              id="start-date"
              label="Start date"
              value={inputValues.from}
              onChange={(e) => {
                setInputValues({ ...inputValues, from: e.target.value })
                const parsedDate = parse(
                  e.target.value,
                  DATE_INPUT_FORMAT,
                  new Date()
                )
                if (isValid(parsedDate)) {
                  setSelected({ ...selected, from: parsedDate })
                  setMonth(parsedDate)
                } else {
                  setSelected({ ...selected, from: undefined })
                }
              }}
            />
            <DateInput
              id="end-date"
              label="End date"
              value={inputValues.to}
              onChange={(e) => {
                setInputValues({ ...inputValues, to: e.target.value })
                const parsedDate = parse(
                  e.target.value,
                  DATE_INPUT_FORMAT,
                  new Date()
                )
                if (isValid(parsedDate)) {
                  setSelected({ ...selected, to: parsedDate } as DateRange)
                  setMonth(parsedDate)
                } else {
                  setSelected({ ...selected, to: undefined } as DateRange)
                }
              }}
            />
          </div>
          <Calendar
            autoFocus
            mode="range"
            month={month}
            onMonthChange={setMonth}
            selected={selected}
            onSelect={handleDayPickerSelect}
            numberOfMonths={2}
          />
        </div>
      </div>
      <div className="flex justify-end gap-3">
        <Button
          weight="tertiary"
          onClick={() => handleDayPickerSelect(undefined)}
        >
          Clear
        </Button>
        <Button onClick={() => onApply(selected)}>Apply</Button>
      </div>
    </div>
  )
}

export default DateRangePicker
