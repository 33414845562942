import { TAirtableCellValueMap } from '@changex/design-system'

export type TAirtableCell<T extends keyof TAirtableCellValueMap> = {
  id: string
  value: TAirtableCellValueMap[T]
}

export type TAirtableCellUnion =
  | TAirtableCell<'attachment'>
  | TAirtableCell<'checkbox'>
  | TAirtableCell<'createdTime'>
  | TAirtableCell<'date'>
  | TAirtableCell<'email'>
  | TAirtableCell<'formula'>
  | TAirtableCell<'multilineText'>
  | TAirtableCell<'multipleAttachments'>
  | TAirtableCell<'multipleSelects'>
  | TAirtableCell<'number'>
  | TAirtableCell<'richText'>
  | TAirtableCell<'singleLineText'>
  | TAirtableCell<'singleSelect'>

export type TSolutionSurveyResponse = {
  id: string
  type: 'survey_responses'
  locationUrl: string
  name: string
  submittedAt: string
  surveyData: TAirtableCellUnion[]
}

export type TSolutionSurveyResponseFieldOrder = {
  id: string
  name: string
  type: keyof TAirtableCellValueMap
}[]

export type TFilters = {
  'filter[region_id]'?: string
  'filter[fund_id]'?: string
}

export type TFilterAction =
  | { type: 'reset' }
  | { type: 'set'; name: keyof TFilters; value: TFilters[keyof TFilters] }

export const EMAIL_FIELD_ID = 'fldjWFJuczjKSYNyI'
