import { DayPicker, DayPickerProps } from 'react-day-picker'
import { WithRequiredProp } from '@changex/design-system'

/**
 * A calendar component for viewing and selecting dates. Thin styling wrapper for
 * [react-day-picker](https://daypicker.dev/).
 *
 * @example
 * <Calendar
 *  mode="single"
 *  selected={myDate}
 *  onSelect={date => console.log(date)}
 *  defaultMonth={myDate}
 * />
 *
 * @see [react-day-picker](https://daypicker.dev/)
 */
const Calendar = (props: WithRequiredProp<DayPickerProps, 'mode'>) => {
  return (
    <DayPicker
      classNames={{
        month_caption: 'text-sm font-medium text-gray-900 flex justify-center',
        month_grid: '-m-2 border-separate border-spacing-3',
        day: 'font-normal text-sm rounded-full border border-transparent',
        day_button: 'h-7 w-7',
        day_disabled: 'text-gray-400',
        today: 'rounded-full border border-teal-400/60',
        selected: 'bg-teal-800 text-white rounded-full',
        range_start: 'bg-teal-800 text-white',
        range_end: 'bg-teal-800 text-white',
        range_middle: 'bg-green-200 text-green-900',
        chevron: 'fill-gray-400',
        months: 'gap-5 flex',
        weekday: 'pt-6 pb-2 text-xs text-gray-500 font-normal',
      }}
      {...props}
    />
  )
}

export default Calendar
