import {
  Skeleton,
  MapApplications,
  MapLegend,
  TMapApplication,
  MapCardBody,
  MapCardHeaderImage,
  MapCardTitle,
  MapCardProfile,
  Badge,
  formatCurrency,
  formatNumber,
  TreeSelector,
  Icon,
  Selector,
  LoadingSpinner,
} from '@changex/design-system'
import { useFetcher } from 'shared/api/fetcher'
import { useParams } from 'react-router-dom'
import { utils } from 'features/applications/utils'
import classNames from 'classnames'
import { constants } from 'features/applications/constants'
import { useReducer } from 'react'
import { TSolution } from '@features/applications/types'
import DateFilter from './applications-map/DateFilter'
import { format } from 'date-fns'

type TFilters = {
  'filter[state]'?: string[]
  'filter[fund_id]'?: string
  'filter[start_date]'?: Date
  'filter[end_date]'?: Date
}

function filtersReducer(
  filters: TFilters,
  action:
    | { type: 'reset' }
    | { type: 'set'; name: keyof TFilters; value: TFilters[keyof TFilters] }
) {
  switch (action.type) {
    case 'reset':
      return {}
    case 'set':
      return {
        ...filters,
        [action.name]: action.value,
      }
  }
}

const filtersObjectToQueryString = (filters: TFilters) => {
  const result: Record<string, string> = {}
  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value = value.join(',')
    }
    if (value instanceof Date) {
      value = format(value, 'yyyy-MM-dd')
    }
    if (value !== undefined && value !== null && value !== '') {
      result[key] = value
    }
  })
  return result
}

const ApplicationsMap = ({ className }: { className?: string }) => {
  const { solutionId } = useParams()
  const [filters, dispatch] = useReducer(filtersReducer, {})
  const { data: solution, isLoading: isLoadingSolution } =
    useFetcher<TSolution>(`/solutions/${solutionId}`, {
      include: 'funds',
    })
  const { data: mapApplications, isLoading: isLoadingMapApplications } =
    useFetcher<TMapApplication[]>('/location_maps', {
      'filter[solution_id]': solutionId!,
      ...filtersObjectToQueryString(filters),
    })
  const isLoading = isLoadingSolution || isLoadingMapApplications
  const isLoadingInitial = isLoading && !mapApplications
  const isUpdating = isLoading && mapApplications
  const sortedFunds =
    solution?.funds?.sort(
      (a, b) =>
        new Date(b.options.startDate!).getTime() -
        new Date(a.options.startDate!).getTime()
    ) ?? []

  return (
    <div
      className={classNames(
        'relative flex flex-col gap-4 rounded bg-white p-4',
        { 'opacity-90': isUpdating },
        className
      )}
    >
      <h3 className="sr-only">Map of applications</h3>
      {isLoadingInitial && (
        <>
          <div className="flex flex-wrap items-center gap-x-3">
            <Skeleton className="w-28" />
            <Skeleton className="w-28" />
          </div>
          <Skeleton className="h-[43rem] w-full" />
        </>
      )}
      {isUpdating && (
        <div className="absolute left-1/2 top-1/2 z-50 text-white">
          <LoadingSpinner />
        </div>
      )}
      {solution && mapApplications && sortedFunds && (
        <>
          <div className="flex flex-wrap items-center gap-x-3">
            <TreeSelector
              title="Status"
              options={constants.stateCategories}
              value={filters['filter[state]'] ?? []}
              onChange={(value) => {
                dispatch({
                  type: 'set',
                  name: 'filter[state]',
                  value: value,
                })
              }}
            />
            {sortedFunds.length > 0 && (
              <Selector
                filterOptions={{
                  enabled: true,
                  placeholder: 'Search for a fund',
                }}
                onChange={(value) =>
                  dispatch({ type: 'set', name: 'filter[fund_id]', value })
                }
                options={[
                  { id: '', label: 'Fund' },
                  ...sortedFunds.map((fund) => ({
                    id: String(fund.id),
                    label: fund.name,
                  })),
                ]}
                value={filters['filter[fund_id]'] ?? ''}
              />
            )}
            <DateFilter
              selected={{
                from: filters['filter[start_date]'],
                to: filters['filter[end_date]'],
              }}
              onApply={(value) => {
                dispatch({
                  type: 'set',
                  name: 'filter[start_date]',
                  value: value?.from,
                })
                dispatch({
                  type: 'set',
                  name: 'filter[end_date]',
                  value: value?.to,
                })
              }}
            />
            {Object.values(filters).filter((value) => Boolean(value)).length >
              0 && (
              <button
                className="flex items-center text-sm font-medium text-red-600"
                onClick={() => {
                  dispatch({ type: 'reset' })
                }}
              >
                <Icon icon="XIcon" size="xs" />
                Clear filters
              </button>
            )}
          </div>
          <MapApplications
            locationMapApplications={mapApplications}
            heatmapActive={false}
            ApplicationDetails={({ application }) => {
              return (
                <>
                  <MapCardHeaderImage src={solution.headerImageUrl} />
                  <MapCardBody>
                    <div className="py-2">
                      <MapCardTitle url={application.locationPageUrl}>
                        {application.name}
                      </MapCardTitle>
                    </div>
                    <div className="flex justify-between py-2">
                      <div className="flex flex-col">
                        <div className="text-sm text-gray-700 opacity-70">
                          Funding allocated
                        </div>
                        <div className="text-gray-900">
                          {formatCurrency(
                            application.fundingCents / 100,
                            application.fundingCurrency
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-end">
                        <div>
                          <Badge
                            variant={utils.getBadgeStatus(application.state)}
                          >
                            {utils.getStatusLabel(application.state)}
                          </Badge>
                        </div>
                        {application.impactData.beneficiaries && (
                          <div className="mt-2 text-sm text-gray-700 opacity-70">
                            {formatNumber(application.impactData.beneficiaries)}{' '}
                            people impacted
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="py-2">
                      <MapCardProfile
                        name={application.applicantName}
                        avatarUrl={application.applicantPhotoUrl}
                        appliedAt={application.submittedAt}
                      />
                    </div>
                  </MapCardBody>
                </>
              )
            }}
          />
        </>
      )}
      <MapLegend showMarkers={false} />
    </div>
  )
}

export default ApplicationsMap
