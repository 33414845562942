import { TRoute } from '@types'

import DashboardPage from 'app/solutions/[solutionId]/impact/dashboard/page'
import SurveyResponsesPage from 'app/solutions/[solutionId]/impact/survey-responses/page'
import SurveyResponsePage from 'app/solutions/[solutionId]/impact/survey-responses/[surveyResponseId]/page'

const routes: TRoute[] = [
  {
    element: <DashboardPage />,
    path: '/solutions/:solutionId/impact/dashboard',
  },
  {
    element: <SurveyResponsesPage />,
    path: '/solutions/:solutionId/impact/survey-responses',
  },
  {
    element: <SurveyResponsePage />,
    path: '/solutions/:solutionId/impact/survey-responses/:surveyResponseId',
  },
]

export { routes }
