import { AppLayout } from 'shared/layouts'
import { Toaster } from 'react-hot-toast'
import { Skeleton, Breadcrumbs } from '@changex/design-system'
import { useParams } from 'react-router-dom'
import { useFetcher } from 'shared/api/fetcher'
import { TSolutionSurveyResponse } from 'shared/api/survey-responses'
import { SurveyResponse } from './SurveyResponse'
import { Link } from 'react-router-dom'

const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton className="ml-4 h-12 w-[600px]" />
      <div className="mt-6 flex gap-1 pl-8">
        <div className="flex w-[432px] flex-col gap-2">
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
        </div>
        <div className="flex w-[30vw] flex-col gap-2">
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
          <Skeleton className="h-16" />
        </div>
      </div>
    </>
  )
}

const Page = () => {
  const { solutionId, surveyResponseId } = useParams()

  const {
    data: surveyResponse,
    meta: fieldData,
    isLoading,
  } = useFetcher<TSolutionSurveyResponse>(
    `/analytics/solutions/survey_responses/${surveyResponseId}`,
    {
      'filter[solution_id]': solutionId!,
    }
  )

  return (
    <AppLayout>
      <main className="overflow-auto">
        <div className="px-4 pt-8 pb-12">
          <Breadcrumbs
            pages={[
              {
                current: false,
                href: `/solutions/${solutionId}/impact/survey-responses`,
                name: 'Survey responses',
              },
              { current: true, href: '#', name: 'View response' },
            ]}
            Link={({ to, className, children, ...props }) => (
              <Link to={to} className={className} {...props}>
                {children}
              </Link>
            )}
          />
        </div>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <SurveyResponse
            data={surveyResponse!}
            fieldOrder={fieldData!.fieldOrder}
          />
        )}
      </main>
      <Toaster />
    </AppLayout>
  )
}

export default Page
