import DateRangePicker from 'shared/components/DateRangePicker'
import { Popover } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { DateRange } from 'react-day-picker'
import { formatDateShort } from '@changex/design-system'

const DateFilter = ({
  selected,
  onApply,
}: {
  selected: DateRange | undefined
  onApply: (selected: DateRange | undefined) => void
}) => {
  return (
    <Popover>
      <Popover.Button className="relative w-full cursor-default rounded-md border border-gray-100 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
        <span className="flex items-center">
          <span className="ml-2 block truncate">
            {selected && selected.from && selected.to
              ? `${formatDateShort(selected.from)} - ${formatDateShort(
                  selected.to
                )}`
              : 'Date applied'}
          </span>
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Popover.Button>
      <Popover.Panel className="absolute z-50 rounded-md bg-white p-4 shadow-lg">
        {({ close }) => (
          <DateRangePicker
            initialValue={selected}
            onApply={(value) => {
              onApply(value)
              close()
            }}
          />
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default DateFilter
